import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import { LegacyPage } from '~/components/legacyPage'

export const loader = async ({ params, request }) => {
  const targetUrl = process.env.TARGET_URL

  return json({ targetUrl })
}

export default function _LegacyPage() {
  const { targetUrl } = useLoaderData()
  return <LegacyPage targetUrl={targetUrl} />
}
