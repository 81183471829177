import { useLocation, useNavigate, useOutletContext } from '@remix-run/react'
import { useEffect, useMemo, useState } from 'react'

export function LegacyPage(props) {
  const { pathname, search } = useLocation()
  const url = `${pathname}${search}`
  const [nextUrl, setNextUrl] = useState(null)
  const [currentUrl, setCurrentUrl] = useState(url)
  const navigate = useNavigate()

  const target = props.targetUrl

  const handlePostMessage = event => {
    if (event.origin !== target) return
    const { data } = event

    if (data.type === 'kalir:iframe-navigate') {
      setNextUrl(data.path)
      navigate(data.path)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handlePostMessage)
    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, [])

  // only reload/rerender the iframe if the navigation happened above us
  useEffect(() => {
    if (nextUrl) {
      if (url === nextUrl) {
        setNextUrl(null)
      }
    } else {
      setCurrentUrl(url)
    }
  }, [url, nextUrl])

  return (
    <div
      className="legacy-page-holder"
      style={{ display: nextUrl ? 'none' : 'block' }}
    >
      {useMemo(
        () => (
          <iframe
            title="old-app-frame"
            src={new URL(currentUrl, target).toString()}
            key={currentUrl}
            className="legacy-page"
          ></iframe>
        ),
        [currentUrl],
      )}
    </div>
  )
}
